.project-page {
    .project-main {
      display: flex;
      flex-direction: row-reverse;
      flex-wrap: wrap;
      min-height: 80vh;
      margin-bottom: 20px;
  
      & > * {
        flex-grow: 1;
        margin: 1% 5%;
        flex-basis: 300px;
      }
  
      .project-main-image {
        background-size: contain;
        background-repeat: no-repeat;
        background-position-x: 50%;
        // width: 40%;
        min-height: 60vh;
      }
  
      .project-main-description {
        width: 40%;
  
        h1 {
          color: $off-white
        }
        
        .project-main-buttons {
          text-align: center;
          
          .bn39 {
            background-image: linear-gradient(135deg, $logo-sand, $logo-grey);
            border-radius: 6px;
            box-sizing: border-box;
            color: #ffffff;
            display: inline-block;
            height: 50px;
            font-size: 1em;
            font-weight: 600;
            padding: 2px;
            position: relative;
            text-decoration: none;
            width: 7em;
            z-index: 2;
            min-width: 150px;
            margin: 10px 50px;
  
            &:hover {
              color: #ffffff;
            }
          }
  
          .project-page-link {
            font-size: 1em;
            font-weight: 600;
            display: inline-block;
            width: 100%;
            margin-top: 15px;
            padding: 2px;
  
            .project-page-link-span {
              text-decoration: underline;
  
              &:hover {
                color: #000000;
              }
            }
          }
          
          
          .bn39 .bn39span {
            align-items: center;
            background: #0e0e10;
            border-radius: 6px;
            display: flex;
            justify-content: center;
            height: 100%;
            transition: background 0.5s ease;
            width: 100%;
          }
          
          .bn39:hover .bn39span {
            background: transparent;
          }
        }
      }
    }
  }

  @media (max-width: 767px) {
    .project-page { 
        .project-main { 
          padding-top: 100px;
        }
      }
  }