@import
  'variables.scss',
  'header.scss',
  'home.scss',
  'future-projects.scss',
  'project-page.scss',
  'footer.scss',
  'about.scss',
  'blog.scss',
  'blog-article.scss'
;

/* General */
body {
  background-color: #333;
  padding: 0;
}

.container,
.content-section,
footer {
  max-width: 1200px;
  margin: auto;
}
.content-section {
  margin-bottom: 3.75rem;
}

/* Text Sections */
.text-section-2col {
  -webkit-column-count: 2; /* Chrome, Safari, Opera */
  -moz-column-count: 2; /* Firefox */
  column-count: 2;
  -webkit-column-gap: 40px; /* Chrome, Safari, Opera */
  -moz-column-gap: 40px; /* Firefox */
  column-gap: 40px;
}
.text-section-1col img,
.text-section-2col img,
.gallery img {
  margin-bottom: 1rem;
}
.text-section-1col p:last-child,
.text-section-2col p:last-child {
  margin-bottom: 0;
}
.quote blockquote {
  display: block;
  font-family: "Lora", Serif;
  font-size: 36px;
  font-style: italic;
  font-weight: normal;
  color: #484d52;
  letter-spacing: 1.14;
  line-height: 1.5em;
  quotes: "\201C""\201D""\2018""\2019";
  text-align: center;
}
.quote blockquote:before,
.quote blockquote:after {
  color: #e9e9e9;
  content: open-quote;
  font-family: "Lora", Serif;
  font-size: 2.5em;
  font-weight: 900;
  line-height: 0.1em;
  margin-left: 10px;
  margin-right: 10px;
  vertical-align: -0.3em;
}
.quote blockquote:after {
  content: close-quote;
}

/* Highlight Section */
.highlight {
  position: relative;
  overflow: auto;
}
.highlight-left {
  width: 43%;
  float: left;
}
.highlight-right {
  width: 48%;
  float: right;
}

/* Gallery Section */
.gallery {
  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox; /* TWEENER - IE 10 */
  display: -webkit-flex; /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
  display: flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: space-between;
  justify-content: space-between;
}
.gallery-item {
  -webkit-box-flex: 0 1 48%;
  -moz-box-flex: 0 1 48%;
  -webkit-flex: 0 1 48%;
  -ms-flex: 0 1 48%;
  flex: 0 1 48%;
}
.gallery-link {
  margin-top: -20px;
  text-transform: uppercase;
}

/* 404 error page */
.not-found {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 50vw;
  align-items: center;
}

/*
 * Loader animation
 */
#loader {
  position: fixed;
  top: 50%;
  left: 50%;
  -moz-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.lds-ripple {
  width: 64px;
  height: 64px;
}
.lds-ripple:before,
.lds-ripple:after {
  content: "";
  position: absolute;
  border: 4px solid #333;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.lds-ripple:after {
  animation-delay: -0.5s;
}
@keyframes lds-ripple {
  0% {
    top: 28px;
    left: 28px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: -1px;
    left: -1px;
    width: 58px;
    height: 58px;
    opacity: 0;
  }
}

@keyframes fadeIn {
  from {
      opacity: 0;
  }

  to {
      opacity: 1;
  }
}

/* Media Queries */
@media (max-width: 767px) {
  h1 {
    font-size: 32px;
    line-height: 40px;
  }
  h2 {
    font-size: 26px;
  }
  h3 {
    font-size: 18px;
  }
  .content-section {
    margin-bottom: 2rem;
  }

  .banner-title {
    font-size: 50px;
    line-height: 50px;
  }
  .text-section-2col {
    -webkit-column-count: 1; /* Chrome, Safari, Opera */
    -moz-column-count: 1; /* Firefox */
    column-count: 1;
    -webkit-column-gap: 40px; /* Chrome, Safari, Opera */
    -moz-column-gap: 40px; /* Firefox */
    column-gap: 40px;
  }
  .quote {
    font-size: 20px;
  }
  .gallery-item {
    -webkit-box-flex: 100%;
    -moz-box-flex: 100%;
    -webkit-flex: 100%;
    -ms-flex: 100%;
    flex: 100%;
  }
  .highlight-left,
  .highlight-right {
    width: 100%;
    float: none;
  }
}
