.about {
    .content-section { 
        padding: 50px 50px 0;

        .block-img {
            text-align: center;
            
            img {
                max-width: 400px;
            }
        }
    }
}

@media (max-width: 767px) {
    .about {
        .content-section { 
            padding: 120px 20px;
        }
    }
  }

@media (max-width: 448px) {
    .about {
        .content-section {
            .block-img {
                img {
                    max-width: 100%;
                }
            }
        }
    }
}