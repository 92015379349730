.homepage {
    .homepage-banner { 
      width: 100%;
      display: inline-block;
      margin: auto;
      color: #ffffff;
      line-height: 1.75;
      text-align: center;
  
      .banner-content {
        display: inline-flex;
        flex-wrap: wrap;
        text-align: center;
        
        .latest-project-container {
          background-size: cover;
          background-position-x: 50%;
  
          .latest-project-image { 
            border-radius: 10%;
          }
  
          .latest-project-content { 
            min-height: 80%;
            width: 30%;
            float: right;
            margin: 50px;
            background-color: $off-white;
            border-radius: 10px;
            padding: 20px;
            text-align: left;
            
            .latest-project-text {
              color: $dark-grey;
            }
  
            
            .latest-project-buttons {
              text-align: center;
              
              .bn39 {
                background-image: linear-gradient(135deg, $logo-sand, $logo-grey);
                border-radius: 6px;
                box-sizing: border-box;
                color: #ffffff;
                display: inline-block;
                height: 50px;
                font-size: 1em;
                font-weight: 600;
                padding: 2px;
                position: relative;
                text-decoration: none;
                width: 7em;
                z-index: 2;
                min-width: 150px;
                margin: 10px 50px;
  
                &:hover {
                  color: #ffffff;
                }
              }
  
              .project-page-link {
                font-size: 1em;
                font-weight: 600;
                display: inline-block;
                width: 100%;
                margin-top: 15px;
                padding: 2px;
  
                .project-page-link-span {
                  text-decoration: underline;
  
                  &:hover {
                    color: #000000;
                  }
                }
              }
              
              
              .bn39 .bn39span {
                align-items: center;
                background: #0e0e10;
                border-radius: 6px;
                display: flex;
                justify-content: center;
                height: 100%;
                transition: background 0.5s ease;
                width: 100%;
              }
              
              .bn39:hover .bn39span {
                background: transparent;
              }
            }
          }
        }
        
        .other-projects-container {
          margin: 20px 0;
          width: 100%;
  
          h1 { 
            color: $off-white;
          }
  
          .other-projects {
            display: flex;
            flex-wrap: wrap;
            flex-direction: row;
            // float: right;
  
            .other-projects-box {
              flex-basis: 200px;
              flex-grow: 1;
              width: 25%;
              background-color: #fff7e6;
              margin: 10px;
              border-radius: 7px;
              overflow: hidden;
  
              .other-projects-image { 
                height: 250px;
                width: 100%;
                border-top-left-radius: 7px;
                border-top-right-radius: 7px;
                background-size: cover;
                background-repeat: no-repeat;
                background-position-x: 50%;
                transition: all 0.5s ease;
              }
  
              img { 
                max-height: 250px;
              }
    
              .other-projects-title {
                z-index: 10;
                position: relative;
                background-color: #fff7e6;
                color: $dark-grey;
              }
  
              &:hover { 
                .other-projects-image { 
                  transform: scale(1.05);
                }
  
                .other-projects-title {
                  color: #000000;
                  text-decoration: underline;
                }
              }
            }
          }
        }
      }
    }
  
    .paralax-image {
      background-size: cover;
      background-repeat: no-repeat;
      background-position: 50% 50%;
      height: 60vh;
      transition: 0.1s linear;
    }
}

/* Media Queries */
@media (max-width: 894px) {
    .homepage{
      .homepage-banner {
        .banner-content {
          .other-projects-container{
            .other-projects{
              .other-projects-box {
                  flex-basis: 275px;
              }
            }
          }
        }
      }
    }
}

@media (max-width: 767px) {
    .homepage { 
        .homepage-banner {
          .banner-content {
            margin-top: 100px;
            
            .latest-project-container {
              .latest-project-banner {
                padding: 80px 0 20px;
              }
      
              .latest-project-content { 
                min-height: fit-content;
                float:none;
                width: auto;
                margin: 100px 50px 0;
              }
            }
          }
        }
      }
}

@media (max-width: 405px) {
    .homepage { 
      .homepage-banner {
        .banner-content {        
          .latest-project-container {
            .latest-project-content { 
              margin: 100px 1% 0;
            }
          }
        }
      }
    }
}