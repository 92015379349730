.blog {
    .blog-container {
        padding: 50px 0;
        min-height: 65vh;

        .blog-article-box {
            display: block;
            max-width: 420px;
            margin: auto;
            padding: 20px;
        }

        .blog-article-box:not(:last-child) {
            border-bottom: 1px solid #484d52;
        }
    }
}

@media (max-width: 767px) {
    .blog {
        .blog-container {
            padding-top: 120px;
        }
    }
}