.blog-article {
    .back-to-blog {
        margin: 10px 50px;

        a {
            &:hover {
                color: #ffffff;
            }
        }
    }
    .content-section {
        padding: 3.75rem 50px 0;
    }

    .full-width-image {
        img {
            display: block;
            margin: auto;
        }
    }
}

@media (max-width: 767px) {
    .blog-article {
        .back-to-blog {
            padding: 110px 10px 0;
            margin: 0;
        }

        .content-section{
            padding: 30px 25px 0;
        }
    } 
}
