/* Header */
.site-header {
    height: 20px;
    padding: 10px 0;
    background-image: linear-gradient(135deg, $logo-grey, $logo-sand);
    font-weight: 700;
    font-size: 14px;
    position: relative;
    z-index: 200;
  
    a {
      color: #f3f3f3;
      font-weight: 700;
      font-size: 14px;
      line-height: 1;
  
      &:hover { 
        cursor: pointer;
      }
    }
  
    nav {
      float: right;
      margin-top: -4px;
  
      a:hover { 
        color: #c8c9cb;
      }
  
      ul {
        margin: 0;
      }
  
      li {
        display: inline-block;
        margin-left: 40px;
      }
  
      li.projects-dropdown-menu {
        height: 34px;
      }
  
      li:last-child {
        margin-right: 40px;
      }
  
      .projects-dropdown-menu {
        .projects-dropdown-menu-container {
          display: none;
  
          &.show {
            display: block;
            animation: fadeIn 0.5s;
          }
          // transition: all 0.5s ease;
  
          ul {
            position: absolute;
            background-color: black;
            top: 40px;
            right: 0;
            width: fit-content;
            border-bottom-left-radius: 8px;
            border-bottom-right-radius: 8px;
    
            li { 
              display: block;
              margin: 10px 50px 10px 20px;
            }
          }
        }
      }
    }
  
    .logo { 
      height: 100%;
      display: inline-flex;
      font-size: 22px;
      font-weight: 900;
  
      span {
        display: inline-flex;
      }
  
      img { 
        width: 50px;
      }
    }
  }

  /* Media Queries */
@media (max-width: 1060px) {
    .site-header {
        padding-left: 20px;
        padding-right: 20px;
    }
}

@media (max-width: 767px) {
    .site-header {
        height: auto;
        position: absolute;
        left: 0;
        right: 0;
    
        .logo {
          display: flex;
          justify-content: center;
          align-items: center;
    
          * {
            margin: 0 5px;
          }
        }
    
        nav {
          float: none;
          text-align: center;
    
          li {
            display: inline-block;
            margin-left: 10px;
            margin-right: 10px;
    
            &:last-child {
              margin-right: 10px
            }
          }
    
          .projects-dropdown-menu {
            padding-bottom: 10px;
            margin-bottom: -10px;
    
            a {
              user-select: none;
            }
    
            .projects-dropdown-menu-container { 
              ul { 
                top: 100px;
                width: 100%;
    
                li {
                  margin: 10px 50px;
                }
              }
            }
          }
        }
      }
}