.future-projects-timeline {
    display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      min-height: 100vh;
      margin: 0;
  
    h1 {
      text-align: center;
    }
  
    .timeline-container {
        display: flex;
        flex-direction: column;
        position: relative;
        margin: 40px 0;
        overflow: hidden;
    }
  
    .timeline-container::after {
        background-color: #cb997e;
        content: '';
        position: absolute;
        left: calc(50% - 2px);
        width: 4px;
        height: 100%;
    }
  
    .timeline-item {
        display: flex;
        justify-content: flex-end;
        padding-right: 30px;
        position: relative;
        margin: 10px 0px 10px -27.5px;
        width: 50%;
    }
  
    .timeline-item:nth-child(odd) {
        align-self: flex-end;
        justify-content: flex-start;
        margin-right: -27.5px;
        padding-left: 30px;
        padding-right: 0;
    }
  
    .timeline-item-content {
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
        border-radius: 5px;
        background-color: #fff;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        padding: 15px;
        position: relative;
        max-width: 70%;
    }
  
    .timeline-item-content::after {
        content: ' ';
        background-color: #fff;
        box-shadow: 1px -1px 1px rgba(0, 0, 0, 0.2);
        position: absolute;
        right: -7.5px;
        top: calc(50% - 7.5px);
        transform: rotate(45deg);
        width: 15px;
        height: 15px;
    }
  
    .timeline-item:nth-child(odd) .timeline-item-content {
        text-align: left;
        align-items: flex-start;
    }
  
    .timeline-item:nth-child(odd) .timeline-item-content::after {
        right: auto;
        left: -7.5px;
        box-shadow: -1px 1px 1px rgba(0, 0, 0, 0.2);
    }
  
    .timeline-item-content .tag {
        font-size: 12px;
        font-weight: bold;
        letter-spacing: 1px;
        padding: 5px;
        text-transform: uppercase;
    }
  
    .timeline-item:nth-child(odd) .timeline-item-content .tag {
        left: auto;
        right: 5px;
    }
  
    .timeline-item-content time {
        color: #777;
        font-size: 12px;
        font-weight: bold;
    }
  
    .timeline-item-content p {
        font-size: 16px;
        line-height: 24px;
        margin: 15px 0;
        max-width: 100%;
    }
  
    .timeline-item-content a {
      color: #333;
      text-decoration: underline;
      font-size: 14px;
      font-weight: bold;

      &:hover {
        color: #9a9a9a
      }
    }
  
    .timeline-item-content .circle {
        background-color: #fff;
        border: 3px solid #cb997e;
        border-radius: 50%;
        position: absolute;
        top: calc(50% - 10px);
        right: -40px;
        width: 20px;
        height: 20px;
        z-index: 100;
    }
  
    .timeline-item:nth-child(odd) .timeline-item-content .circle {
        right: auto;
        left: -40px;
    }
  }

  @media (max-width: 767px) {
    .future-projects-timeline .timeline-container {
        margin: 140px 0;
      }
  }

  @media (max-width: 405px) {
    .future-projects-timeline {
      .timeline-container {
        .timeline-item {
          align-self: flex-start;
          justify-content: flex-start;
          margin-right: -27.5px;
          padding-left: 30px;
          padding-right: 0;
          margin: 10px 0px 10px 15px;
          width: 100%;
  
          .timeline-item-content {
            align-items: flex-start;
            .circle {
              right: auto;
              left: -40px;
            }
            
            &::after {
              right: auto;
              left: -7.5px;
              box-shadow: -1px 1px 1px rgba(0, 0, 0, 0.2);
            }
          }
        }
  
        &::after {
          left: 15px;
        }
      }
    }
  }