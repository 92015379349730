@import 'social-icons.scss';

footer {
    border-top: 1px solid #dadada;
    color: #9a9a9a;
    font-size: 16px;
    font-style: italic;
    text-align: center;

    .footer-column.socials-column {
      float: right;
    }

    .footer-column.menu-column {
      float: left;
    }
  
    .footer-column {
      display: block;
      text-align: left;
      margin: 20px 50px;
      width: max-content;

      .socials-menu {
        list-style-type: none;
        margin: 0;
        padding: 0;
        overflow: hidden;
        background-color: #333333;

        li {
          float: left;
          margin-left: 10px;
        }

        .socials-icon {
          background-size: cover;
          background-repeat: no-repeat;
          display: block;
          height: 20px;
          width: 20px;
          transition: 0.3s;
        }

        .socials-x {
          background-image: $x-icon-grey;

          &:hover {
            background-image: $x-icon-white;
          }
        }

        .socials-instagram {
          background-image: $instagram-icon-grey;

          &:hover {
            background-image: $instagram-icon-white;
          }
        }

        .socials-facebook {
          background-image: $facebook-icon-grey;

          &:hover {
            background-image: $facebook-icon-white;
          }
        }
      }

    }
  
    p {
      padding: 2rem 0;
      margin-bottom: 0;
    }
  
    a {
      font-weight: bold;
      text-decoration: none;
      transition: 0.3s;

      &:hover {
        color: #ffffff;
      }

    }
  
    .footer-logo {
      width: 30px;
      margin-top: 10px;
    }
  }


@media (max-width: 448px) {
  footer {
    .footer-column.socials-column {
      float: left;
    }

    .footer-column {
      .socials-menu {
        li {
          margin-left: 0;
          margin-right: 10px
        }
      }
    }
  }
}